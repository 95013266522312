
const baseUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_API_IMAGES_URL;


const fetchSinToken = ( endpoint: string, data: any, method = 'GET' ) => {
    const url = `${baseUrl}/${endpoint}`;

    if(method === 'GET'){
        return fetch( url );
    }else{
        return fetch(url, {
            method: method,
            headers:{
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
};

const fetchConToken = ( endpoint: string, data: any, method = 'GET' ) => {
    const url = `${baseUrl}/${endpoint}`;
    const token = localStorage.getItem('token') || '';
    if(method === 'GET'){
        return fetch( url,{
            method,
            headers: {
                'x-token': token,
            }
        } );
    }else{
        return fetch(url, {
            method: method,
            headers:{                
                'Content-type': 'application/json',
                'x-token': token
            },
            body: JSON.stringify(data)
        });
    }
};

const fetchImages = ( endpoint: string, data: any, method = 'GET' ) => {
    const url = `${imgUrl}/${endpoint}`;
    if(method === 'GET'){
        return fetch( url );
    }else{
        return fetch(url, {
            method: method,
            mode: "cors",
            headers:{
                'Content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
    }
}


const fetchImagesToken = ( endpoint: string, data: any, method = 'GET' ) => {
    const url = `${imgUrl}/${endpoint}`;
    const token = localStorage.getItem('access_token') || '';
    
    if(method === 'GET'){
        return fetch( url,{
            method,
            headers: {
                'Authorization':  `Bearer ${token}`,
            }
        } );
    }else{
        return fetch(url, {
            method: method,
            headers:{                
                'Content-type': 'application/json',
                'Authorization':  `Bearer ${token}`,
            },
            body: JSON.stringify(data)
        });
    }
}

export {
    fetchImages,
    fetchConToken,
    fetchSinToken,
    fetchImagesToken,
}