import { ChangeEventHandler, FormEvent, MouseEvent } from "react";
import {
	Button,
	Link,
	Box,
	Grid,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Email } from '@mui/icons-material'
import { LoginState } from "../../interfaces/auth";

interface Props {
	values: LoginState,
	handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
	handleClickShowPassword: () => void;
	handleMouseDownPassword: (event:  MouseEvent<HTMLButtonElement>) => void;
	onChange: (prop: keyof LoginState) => ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const LoginForm = (
	{ values,
		handleSubmit,
		handleClickShowPassword,
		handleMouseDownPassword,
		onChange
	}: Props) => {
	return (
		<Box
			component="form"
			noValidate
			onSubmit={handleSubmit}
			autoComplete="off"
			sx={{ mt: 1 }}
		>		
			<FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
				<InputLabel htmlFor="email">
					Correo electrónico *
				</InputLabel>
				<OutlinedInput
					id="email"
					type="email"
					value={values.email}
					name='email'
					autoComplete="username"
					required
					onChange={onChange("email")}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="email"
								edge="end"
							>
								<Email />
							</IconButton>
						</InputAdornment>
					}
					label="Correo electrónico *"
				/>
			</FormControl>
			<FormControl fullWidth sx={{ mt: 1 }} variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">
					Contraseña *
				</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					type={values.showPassword ? "text" : "password"}
					value={values.password}
					name='password'
					autoComplete="current-password"
					required
					onChange={onChange("password")}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{values.showPassword ? (
									<VisibilityOff />
								) : (
									<Visibility />
								)}
							</IconButton>
						</InputAdornment>
					}
					label="Contraseña *"
				/>
			</FormControl>
			<Button
				type="submit"
				fullWidth
				variant="contained"
				sx={{ mt: 3, mb: 2 }}
			>
				Ingresar
			</Button>
			<Grid container>
				<Grid item>
					<Link href="#" variant="body2">
						¿Olvidaste tu contraseña?
					</Link>
				</Grid>
			</Grid>
		</Box>
	)
}
