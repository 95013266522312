import { fetchConToken, fetchImages, fetchImagesToken, fetchSinToken } from "../../../apis/fetch";
import { doMessage, startLoading } from "../../ui/actions/ui";
import { AuthAction, User } from "../interfaces/auth";



export const startLogin = (email: string, password: string) => {

    return async (dispatch: any) => {
        dispatch(startLoading());
        try {
            const resp = await fetchSinToken('auth/signin', { email, password }, 'POST');
            const body = await resp.json();
            const { errors, msg, ok } = body;
            if (ok) {
                const { user, token } = body;
                const respimg = await fetchImages('login', { email, password }, 'POST');
                const bodyimg = await respimg.json();
                const { status, msg, access_token } = bodyimg;                
                if (status) {
                    localStorage.setItem('access_token', access_token);
                }
                localStorage.setItem('token', token);
                dispatch(login(user, token));
                dispatch(doMessage({ type: 'success', message: msg }));
            } else {                
                if (msg) {                    
                    dispatch(doError(msg));
                    dispatch(doMessage({ type: 'error', message: msg }));                   
                } else {
                    let err = ''
                    const error = Object.values(errors)
                    error.map((e: any) => err += `${e.msg} \n`)
                    dispatch(doMessage({ type: 'error', message: err }));
                    dispatch(doError(err))                    
                }
            }
            
        } catch (error) {
            dispatch(doMessage({ type: 'error', message: '¡Contacta al Administrador!' }));
        }        
        dispatch(startLoading());
    };
}

export const startCheking = () => {
    return async (dispatch: any) => {
        const resp = await fetchConToken('auth/renew', {});       
        const {token, ok, user, msg} = await resp.json();
        if (ok) {
            localStorage.setItem("token", token);
            dispatch(login(user, token));
        } else {
            if(msg === 'No hay token'){
                dispatch(startLogout())
                return
            }
            dispatch(doMessage({ type: 'error', message: msg }));
            dispatch(startLogout())
        }
    };
};



const login = (user: User, token: string): AuthAction => ({
    type: 'signUp',
    payload: {
        user, token
    }
})

const doError = (errorMessage: string): AuthAction => ({
    type: 'addError',
    payload: errorMessage
})

export const startLogout = () => {
    return async (dispatch: any) => {
        
        await fetchImagesToken('logout', {});

        localStorage.removeItem('token');
        localStorage.removeItem('access_token');
        dispatch(doLogout());
    };
}

const doLogout = (): AuthAction => ({
    type: 'logout'
})

export const doRemoveError = (): AuthAction => ({
    type: 'removeError'
})